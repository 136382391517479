<template>
  <div class="text-center">
    <div class="small-sidenav flex two clickable">
      <div class="no-padding-bottom">
        <div class="brand flex" style="height: 100%" @click="redirectToHome()">
          <div style="display: flex; align-items: center; padding-bottom: 0">
            <img class="logo" src="../../assets/lg.png" />
            <span class="nav-brand-name">{{ appName }} <span v-if="!isOnline">(!)</span></span>
          </div>
        </div>
      </div>
      <div style="font-size: 30px" @click="toggleMenu()">
        <div class="burger-icon off-two-third">&#9776;</div>
      </div>
    </div>

    <div
      v-if="isLoading || showSuccess"
      :class="{
        'loading-in-progress': isLoading,
        'loading-success': !isLoading && showSuccess,
      }"
      class="rainbow-loading-line"
    ></div>
    <div
      v-if="!isLoading && showError"
      :class="{ 'loading-error': !isLoading && showError }"
      class="error-message"
    >
      {{ errorMessage || "Error" }}
    </div>

    <div class="sidebar" ref="showMenu">
      <div class="large-icon">
        <img class="logo" src="../../assets/lg.png" />
        <br />
        <h2>{{ appName }} <span v-if="!isOnline">(!)</span></h2>
      </div>
      <a href="javascript:void(0)" class="closebtn" @click="toggleMenu()"
        >&times;</a
      >
      <router-link @click.native="toggleMenu()" to="/" exact="">Home</router-link>
      <router-link v-if="!isLoggedIn" @click.native="toggleMenu()" to="/login" exact-path
        >Login</router-link
      >
      <router-link
        v-if="isLoggedIn"
        @click.native="toggleMenu()"
        to="/list-overview"
        exact-path
        >Todo</router-link>
      <router-link
        v-if="isLoggedIn"
        @click.native="toggleMenu()"
        to="/userinfo"
        >{{ loggedInUser.displayName }}</router-link
      >
      <router-link @click.native="toggleMenu()" to="/about">About</router-link>
      <a v-if="isLoggedIn" @click.stop.prevent="logout()">Logout</a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { A_LOGOUT_USER} from "@/store/storeConstants";
import { loadingCount, loadingStatus, onlineStatus } from '../../service/app-status-service';
export default {
  name: "app-navbar",
  data() {
    return {
      timeoutId: null,
      appName: "Rando",
      showError: false,
      showSuccess: false,
      errorMessage: "",
      loadingCountSubscription: null,
      isOnline: false,
      isOnlineSubscription: null,
      isLoading: false,
      loadingStatusSubscription: null
    };
  },
  created() {
    this.loadingCountSubscription = loadingCount.subscribe((count) => {
      if(count > 0){
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });
    this.loadingStatusSubscription = loadingStatus.subscribe((loadingState) => {
        if (loadingState.code === 1) {
          this.showSuccess = true;
        } else if (loadingState.code === 2) {
          this.showError = true;
          this.errorMessage = loadingState.message;
        }
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null;
          this.showError = false;
          this.showSuccess = false;
        }, 2000);
    });
    this.isOnlineSubscription = onlineStatus.subscribe({
      next: online => this.isOnline = online
    });
  },
  beforeDestroy() {
    this.loadingStatusSubscription.unsubscribe();
    this.isOnlineSubscription.unsubscribe();
    this.loadingCountSubscription.unsubscribe();
  },
  methods: {
    redirectToHome() {
      this.$router.push({ name: "home" });
    },
    toggleMenu() {
      this.$refs.showMenu.classList.toggle("nav-open");
    },
    logout() {
      this.toggleMenu();
      this.A_LOGOUT_USER();
      if (this.$router.currentRoute.name.toString() !== "home") {
        this.$router.replace({ name: "home" });
      }
    },
    ...mapActions([A_LOGOUT_USER]),
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState(["loggedInUser"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

.error-message {
  width: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: larger;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 14%;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar a.closebtn {
  display: none;
}

.sidebar a.active {
  background-color: #4caf50;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

@media only screen and (min-width: $mobile-breakpoint) {
  .small-sidenav.flex {
    display: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #0d0e31;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    box-sizing: border-box;
  }

  .sidebar.nav-open {
    width: 60%;
  }

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 1.5em;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidebar a.closebtn {
    display: block;
    font-size: 2em;
  }

  .sidebar a:hover {
    color: #f1f1f1;
  }

  .small-sidenav.flex {
    border-bottom: 2px solid;
  }
}

.large-icon {
  @media only screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
  border-bottom: 3px solid #013c53;
  img {
    margin-top: 20px;
    width: 40%;
    height: 40%;
  }
}

.small-sidenav .brand {
  text-align: left;
  padding-left: 10%;
}

.small-sidenav .brand img {
  height: 45px;
}

.nav-brand-name {
  font-size: xx-large;
}

.burger-icon {
  position: relative;
  top: 20%;
}

@keyframes rainbow-loading {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 120% 0%;
  }
}

@keyframes loading-success-fadeout {
  from {
    background-color: #66d85c;
    opacity: 1;
  }
  to {
    background-color: white;
    opacity: 0;
  }
}

@keyframes loading-error-fadeout {
  from {
    background-color: #a70303;
    color: white;
    opacity: 1;
  }
  to {
    background-color: white;
    color: transparent;
    opacity: 0;
  }
}

.loading-success {
  animation: loading-success-fadeout 2s cubic-bezier(1, -0.01, 1, 1) 1;
}

.loading-error {
  animation: loading-error-fadeout 2s cubic-bezier(1, -0.01, 1, 1) 1;
}

.loading-in-progress {
  background: repeating-linear-gradient(
    190deg,
    #005fff,
    #00e4ff,
    #9c9c00,
    #005fff
  );
  background-size: 700% 600%;
  animation: rainbow-loading 2s linear infinite;
}

.rainbow-loading-line {
  width: 100%;
  height: 3px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 2;
}
</style>
