import Router from 'vue-router';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import Vuex from 'vuex';
import './registerServiceWorker';
import './styles/styles.scss';

Vue.config.productionTip = false;
Vue.use(Router);
Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
