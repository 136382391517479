<template>
  <div>
    <h2>{{listName}}</h2>
    <button type="button" @click="createNewItem()">New Item</button>
    <div class="flex" v-for="(item, index) in items" :key="index">
      <div class="item flex two-500 no-padding-bottom" :class="{'todo-done': item.done}">
        <div class="">
        <h3>
          {{ item.name }}
          <span @click="editItem(item.id)" class="far fa-edit clickable"></span>
          <span @click="deleteItem(item.id)" class="fas fa-trash clickable"></span>
        </h3>
        </div>
        <div class="font-xx-large">
            <span @click="changeWeight(item, (item.weight || 0 ) + 1)" class="padding-left fas fa-arrow-up clickable"></span>
            <span class="padding-left">{{ item.weight || 0 }}</span>
            <span @click="changeWeight(item, (item.weight || 0 ) - 1)" class="padding-left fas fa-arrow-down clickable"></span>
          <span @click="toggleTodoDone(item)" class="padding-left todo-check-mark fas fa-check-square clickable"></span>
        </div>
        <div class="flex item-description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { A_LOAD_TODO_LIST_ITEMS, A_DELETE_TODO_ITEMS } from "@/store/storeConstants";
import { A_UPDATE_TODO_ITEMS } from '../../store/storeConstants';
export default {
  name: "TodoList",
  props: ["id"],
  data() {
    return {
      items: [],
      listName: ""
    };
  },
  created() {
    this.listName = this.todoLists.find(
      list => list.todoList.id === this.id
    ).todoList.name;
    this[A_LOAD_TODO_LIST_ITEMS]([this.id]).then(userTodoLists => {
      this.items = userTodoLists.find(
        userTodoLists => userTodoLists.todoList.id === this.id
      ).todoList.items;
      this.sortTodoItems();
    });
  },
  methods: {
    ...mapActions([A_LOAD_TODO_LIST_ITEMS, A_DELETE_TODO_ITEMS, A_UPDATE_TODO_ITEMS]),
    editItem(itemId) {
      this.$router.push({
        name: "todo-list-item-edit-with-id",
        params: { id: this.id, itemId: itemId }
      });
    },
    createNewItem() {
      this.$router.push({ name: "todo-list-item-edit", query: { new: true } });
    },
    async deleteItem(itemId) {
      await this.A_DELETE_TODO_ITEMS({todoListId: this.id, items: [itemId]});
    },
    async toggleTodoDone(item) {
      item.done = !item.done;
      await this.updateTodoItem(item);
    },
    sortTodoItems() {
      this.items.sort((t1, t2) => {
        if(Boolean(t1.done) !== Boolean(t2.done)){
          return t1.done ? 1 : -1;
        }
        return (t2.weight || 0) - (t1.weight || 0);
      });
    },
    async updateTodoItem(item){
      await this[A_UPDATE_TODO_ITEMS]({todoListId: this.id, items: [item] });
    },
    async changeWeight(item, newWeight){
      item.weight = newWeight;
      await this.updateTodoItem(item);
      this.sortTodoItems();
    }
  },
  computed: {
    ...mapState(["todoLists"])
  }
};
</script>

<style lang="scss" scoped>
.item {
  border: 1px solid blue;
  transition: all 1.5s;
}

.todo-done {
  &.item {
  border: 1px solid grey;
  opacity: 0.3;
  }

  .todo-check-mark {
    color: greenyellow;
  }
}

.todo-check-mark {
  transition: color 1.5s;
  color: gray;
  font-size: xx-large;
}

.item-description {
  white-space: pre-wrap;
}

.flex-column {
  flex-direction: column;
}

.font-xx-large {
  font-size: xx-large;
}

</style>