import Router from 'vue-router';
import Home from './components/Home.vue';
import Login from "./components/Login.vue";
import About from "./components/About.vue";
import UserInfo from "./components/UserInfo.vue";
import TodoListOverview from "./components/todo/TodoListOverview.vue";
import TodoListEdit from "./components/todo/TodoListEdit.vue";
import TodoList from "./components/todo/TodoList.vue";
import TodoItemEdit from "./components/todo/TodoItemEdit.vue";
import store from './store/store';


const authenticationRequiredGuard = function(to, from, next){
  if(!store.getters.isLoggedIn){
    next({name: "login", query: {returnTarget: to.fullPath}});
  }
  next();
};

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/userinfo',
      name: 'userinfo',
      component: UserInfo,
      beforeEnter: authenticationRequiredGuard
    },
    {
      path: '/list-overview',
      name: 'todo-list-overview',
      component: TodoListOverview,
      beforeEnter: authenticationRequiredGuard
    },
    {
      path: '/list-edit',
      name: 'todo-list-edit',
      component: TodoListEdit,
      beforeEnter: authenticationRequiredGuard,
      props: (route) => ({ isNew: route.query.new, id: route.params.id })
    },
    {
      path: '/list-edit/:id',
      name: 'todo-list-edit-with-id',
      component: TodoListEdit,
      beforeEnter: authenticationRequiredGuard,
      props: (route) => ({ isNew: route.query.new, id: route.params.id })
    },
    {
      path: '/todo-list/:id',
      name: 'todo-list',
      component: TodoList,
      beforeEnter: authenticationRequiredGuard,
      props: (route) => ({ id: route.params.id })
    },
    {
      path: '/todo-list/:id/item/:itemId',
      name: 'todo-list-item-edit-with-id',
      component: TodoItemEdit,
      beforeEnter: authenticationRequiredGuard,
      props: (route) => ({ isNew: route.query.new, todoListId: route.params.id, itemId: route.params.itemId })
    },
    {
      path: '/todo-list/:id/item',
      name: 'todo-list-item-edit',
      component: TodoItemEdit,
      beforeEnter: authenticationRequiredGuard,
      props: (route) => ({ isNew: route.query.new, todoListId: route.params.id })
    }
    
  ]
});

export default router;