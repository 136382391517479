import {BehaviorSubject} from "rxjs";

export const updateAvailable = new BehaviorSubject(false);

// loading status - int
// + optional error message
// change store action to publish via this subject
// 1 = success
// 2 = error
// 0 = loading
export const loadingCount = new BehaviorSubject(0);
export const loadingStatus = new BehaviorSubject({
    code: 0,
    message: ""
});

export function increaseLoadingCount() {
    loadingCount.next(loadingCount.value + 1);
}

export function loadingError(error) {
    loadingCount.next(loadingCount.value - 1);
    loadingStatus.next({
        code: 2,
        message: error
    });
}
export function loadingSuccess() {
    loadingCount.next(loadingCount.value - 1);
    loadingStatus.next({
        code: 1
    });
}

export const onlineStatus = new BehaviorSubject(true);