import { IDBService } from "@/service/db-service";
import {REMEMBER_ME_STORE} from "./db-service";

export class UserDbService extends IDBService {
    constructor() {
        super();
    }

    async saveRememberMeUser(user) {
        const db = await this.getDb();
        return db.put(REMEMBER_ME_STORE, user, user.id);
    }

    async deleteRememberMeUser(user) {
        return (await this.getDb()).delete(REMEMBER_ME_STORE, user.id);
    }

    async isUserRememberMeActivated(user) {
        const isUserRemembered = await (await this.getDb()).get(REMEMBER_ME_STORE, user.id);
        return isUserRemembered !== null && isUserRemembered !== undefined;
    }

    async getRememberMeSavedUsers(){
        return (await this.getDb()).getAll(REMEMBER_ME_STORE);
    }
}