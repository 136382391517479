<template>
  <div>
    <h1 v-if="loggedInUser">Hello ... {{ loggedInUser.displayName }}</h1>
    <h1 v-else>Hello ... Bla bla</h1>
    <div>
      Look forward to some ... maaagiiic ...
      <span class="fas fa-cookie-bite"></span>
    </div>
    <div>
      <h3>Previous logins:</h3>
      <div
        class="clickable"
        @click="loginWithRemeberedUser(user)"
        v-for="user in rememberedUsers"
        :key="user.id"
      >
        {{ user.email }} - {{ user.displayName }}
        <button class="error" @click.stop="deleteRememberedUser(user)">
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { UserDbService } from "@/service/user-db-service";
import { BACKEND_URL } from "../constants";
import { mapState } from 'vuex';

export default {
  name: "home",
  data() {
    return {
      rememberedUsers: [],
    };
  },
  async created() {
    this.userDB = new UserDbService();
    await this.loadRememberedUsers();
  },
  methods: {
    apiAuthUrl(userId, authSource) {
      return `${BACKEND_URL}/api/auth?source=${authSource || "google"}&return=true&return-target=${this.currentUrl()}&login-hint=${userId}`;
    },
    currentUrl() {
      return encodeURIComponent(window.location.origin+"/" + this.$router.resolve("login").href);
    },

    async loadRememberedUsers() {
      this.rememberedUsers = await this.userDB.getRememberMeSavedUsers();
    },
    loginWithRemeberedUser(user) {
      window.location.assign(this.apiAuthUrl(user.id, user.authSource));
    },
    async deleteRememberedUser(user) {
      await this.userDB.deleteRememberMeUser(user);
      this.loadRememberedUsers();
    },
  },
  computed: {
    ...mapState(["loggedInUser"])
  }
};
</script>
