import {BACKEND_URL} from "../constants";

export function registerBackgroundLoginCallback() {
    window.addEventListener("message", (e) => {
    // eslint-disable-next-line
    // was it a success? -> extract token and set it
    // was it an error -> display & relogin
    // eslint-disable-next-line
        console.log(e.data);
    });
}

const backgroundLoginHtmlPath = encodeURIComponent(window.location.origin+"/background-login.html");
const silentLoginUrl = BACKEND_URL+"/api/auth?source=google&return=true&return-target="+backgroundLoginHtmlPath;

export function loginUser(user) {
    const redirectUrl = silentLoginUrl + "&user-id="+user.id;
    // eslint-disable-next-line
    console.log("Iframe to: ", redirectUrl);
    document.getElementById("background-login-iframe").src = redirectUrl;
}