import {openDB} from "idb";

export const IDBName = "Random-world";
export const REMEMBER_ME_STORE = 'remember-me-user';

export class IDBService {
    constructor() {
        this.version = 1;
    }
    
    async getDb(){
        if(!this.db) {
            this.db = await openDB(IDBName, this.version, {
                upgrade(db) {
                    db.createObjectStore(REMEMBER_ME_STORE);
                },
                // blocked() {
                //     // …
                // },
                // blocking() {
                //     // …
                // },
                // terminated() {
                //     // …
                // },
            });
        }
        return this.db;
    }
}