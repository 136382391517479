<template>
  <div>
    <h1>This is an about page</h1>
    <div v-if="updateAvailable">
      There is an update available!
      <button type="button" @click="updateApp()">Install now</button>
    </div>
    <div v-else>
      You are up-to-date!
    </div>
    <div>
      &lt;{{ commitVersion }}&gt;
    </div>
  </div>
</template>
<script>
import { registration } from '../registerServiceWorker';
import { updateAvailable } from '../service/app-status-service';
export default {
  data(){
    return {
      updateAvailable : false,
      updateAvailableSubscription: null,
      commitVersion: process.env.VUE_APP_COMMIT_REF
    };
  },
  methods: {
    updateApp(){
      registration.waiting.postMessage('skipWaiting');
      window.location.reload();
    }
  },
  created(){
    if(registration) registration.update();
    this.updateAvailableSubscription = updateAvailable.subscribe(upAvail => this.updateAvailable = upAvail);
  },
  beforeDestroy(){
    this.updateAvailableSubscription.unsubscribe();
  }
};
</script>