import {M_SET_LOGGED_IN_USER, A_DELETE_TODO_LIST, M_DELETE_TODO_LIST, A_SAVE_TODO_LIST, M_UPDATE_TODO_LIST, A_UPDATE_USER, A_LOGIN_USER, M_SET_TODO_LISTS, A_LOAD_TODO_LISTS, M_ADD_TODO_LIST, A_LOAD_TODO_LIST_ITEMS, A_SAVE_TODO_ITEMS, M_SET_TODO_LIST_ITEMS_ON_LIST, A_UPDATE_TODO_ITEMS, A_DELETE_TODO_ITEMS, M_ADD_TODO_ITEMS, M_DELETE_TODO_ITEMS, M_UPDATE_TODO_ITEMS} from "./storeConstants";
import {setToken, graphqlClient2} from "../apollo-client";
import {MUTATION_DELETE_TODO_LIST, MUTATION_ADD_TODO_LIST, MUTATION_UPDATE_TODO_LIST, QUERY_GET_USER_TODO_LISTS, UPDATE_USER_MUTATION, QUERY_GET_TODO_LIST_ITEMS_FOR_LIST_ID, MUTATION_ADD_TODO_LIST_ITEMS, MUTATION_UPDATE_TODO_LIST_ITEMS, MUTATION_DELETE_TODO_LIST_ITEMS, QUERY_GET_USER_INFO} from "../graphql-queries";

export class Actions {

  async [A_LOAD_TODO_LISTS]({commit}) {
    const todoLists = await graphqlClient2.query({
      operationName: null,
      query: QUERY_GET_USER_TODO_LISTS,
      variables: {
        todoListIds: []
      }
    });
    commit(M_SET_TODO_LISTS, todoLists.data.userTodoLists);
    return todoLists.data.userTodoLists;
  }

  async [A_LOGIN_USER]({commit}, apiToken) {
    setToken(apiToken);
    const userInfoResponse = await graphqlClient2.query({
      operationName: null,
      query: QUERY_GET_USER_INFO
    });
    const loggedInUser = userInfoResponse.data.userInfo;
    const token = userInfoResponse.apiToken ? userInfoResponse.apiToken : apiToken;
    Object.assign(loggedInUser, {apiToken: token});
    commit(M_SET_LOGGED_IN_USER, loggedInUser);
    return loggedInUser;
  }

  async [A_UPDATE_USER]({commit, state}, user) {
    const response = await graphqlClient2.query({
      operationName: null,
      // Query
      query: UPDATE_USER_MUTATION,
      // Parameters
      variables: {
        id: state.loggedInUser.id,
        displayName: user.displayName,
        email: user.email,
        active: user.active,
      },
    });
    const userInfo = response.data.updateUser;
    commit(M_SET_LOGGED_IN_USER, {user: userInfo.user, apiToken: userInfo.token ? userInfo.token : state.apiToken});
    setToken(userInfo.token);

    return response;
  }

  async [A_DELETE_TODO_LIST]({commit}, {id}) {
    const response = await graphqlClient2.query({
      operationName: null,
      query: MUTATION_DELETE_TODO_LIST,
      variables: {
        todoList: {id}
      }
    });
    commit(M_DELETE_TODO_LIST, response.data.deleteTodoList);
    return response.data.deleteTodoList;
  }

  async [A_SAVE_TODO_LIST]({commit}, {todoList, isNew}) {
    let savedTodoListResponse;
    if(isNew) {
      const response = await graphqlClient2.query({
        operationName: null,
        query: MUTATION_ADD_TODO_LIST,
        variables: {
          todoList
        }
      });
      savedTodoListResponse = response.data.addTodoList;
      commit(M_ADD_TODO_LIST, savedTodoListResponse);

    } else {
      const response = await graphqlClient2.query({
        operationName: null,
        query: MUTATION_UPDATE_TODO_LIST,
        variables: {
          todoList
        },
      });
      savedTodoListResponse = response.data.updateTodoList;
      commit(M_UPDATE_TODO_LIST, savedTodoListResponse);
    }
    return savedTodoListResponse;

  }

  async [A_LOAD_TODO_LIST_ITEMS]({commit}, todoListIds) {
    const newTodoListIds = todoListIds;
    const response = await graphqlClient2.query({
      operationName: null,
      query: QUERY_GET_TODO_LIST_ITEMS_FOR_LIST_ID,
      variables: {
        todoListIds: newTodoListIds
      }
    });
    response.data.userTodoLists.forEach(userTodoList => commit(M_SET_TODO_LIST_ITEMS_ON_LIST, userTodoList));
    return response.data.userTodoLists;
  }

  async [A_SAVE_TODO_ITEMS]({commit}, {todoListId, items}) {
    const response = await graphqlClient2.query({
      operationName: null,
      query: MUTATION_ADD_TODO_LIST_ITEMS,
      variables: {
        todoList: todoListId,
        items
      }
    });
    commit(M_ADD_TODO_ITEMS, {id: todoListId, items: response.data.addTodoListItems});
    return response;
  }

  async [A_UPDATE_TODO_ITEMS]({commit}, {todoListId, items}) {
    const response = await graphqlClient2.query({
      operationName: null,
      query: MUTATION_UPDATE_TODO_LIST_ITEMS,
      variables: {
        todoList: todoListId,
        items
      }
    });
    commit(M_UPDATE_TODO_ITEMS, {id: todoListId, items: response.data.updateTodoListItems});
    return response;
  }

  async [A_DELETE_TODO_ITEMS]({commit}, {todoListId, items}) {
    const response = await graphqlClient2.query({
      operationName: null,
      query: MUTATION_DELETE_TODO_LIST_ITEMS,
      variables: {
        todoList: todoListId,
        items
      }
    });

    commit(M_DELETE_TODO_ITEMS, {id: todoListId, items: response.data.deleteTodoItems});
    return response;
  }

}
