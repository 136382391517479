import {M_ADD_TODO_LIST, M_DELETE_TODO_LIST, M_UPDATE_TODO_LIST, M_SET_TODO_LISTS, M_SET_LOGGED_IN_USER, M_DEC_LOADING, M_INC_LOADING, M_LOGOUT_USER, M_SET_LOADING_STATUS, M_SET_TODO_LIST_ITEMS_ON_LIST, M_DELETE_TODO_ITEMS, M_UPDATE_TODO_ITEMS, M_ADD_TODO_ITEMS} from "./storeConstants";

export const mutations = {
  [M_ADD_TODO_LIST](state, payload) {
    state.todoLists.push(payload);
  },

  [M_DELETE_TODO_LIST](state, payload) {
    delete state.todoListItems[payload.id];
    state.todoLists = state.todoLists.filter(userTodoList => userTodoList.todoList.id !== payload.id);
  },

  [M_UPDATE_TODO_LIST](state, payload) {
    state.todoLists = state.todoLists.filter(userTodoList => userTodoList.todoList.id !== payload.todoList.id);
    state.todoLists.push(payload);
  },

  [M_SET_TODO_LISTS](state, payload) {
    state.todoLists = payload;
  },

  [M_SET_LOGGED_IN_USER](state, payload) {
    state.loggedInUser = payload.user;
    state.apiToken = payload.apiToken;
  },

  [M_DEC_LOADING](state, payload) {
    state.loadingCount -= payload;
  },

  [M_INC_LOADING](state, payload) {
    state.loadingCount += payload;
  },

  [M_LOGOUT_USER](state) {
    state.loggedInUser = null;
    state.apiToken = null;
  },

  [M_SET_LOADING_STATUS](state, payload) {
    state.loadingStatus = payload;
  },

  [M_SET_TODO_LIST_ITEMS_ON_LIST](state, payload){
    state.todoListItems[payload.todoList.id] = payload.todoList.items;
  },

  [M_DELETE_TODO_ITEMS](state, payload){
    state.todoListItems[payload.id] = state.todoListItems[payload.id].filter(item => !payload.items.some(deleteItem => deleteItem.id === item.id));
  },

  [M_UPDATE_TODO_ITEMS](state, payload){
    state.todoListItems[payload.id] = state.todoListItems[payload.id].filter(item => !payload.items.some(deleteItem => deleteItem.id === item.id));
    state.todoListItems[payload.id].push(...payload.items);
  },

  [M_ADD_TODO_ITEMS](state, payload){
    state.todoListItems[payload.id].push(...payload.items);
  }
};