<template>
  <div id="app">
    <Navbar></Navbar>
    <div class="main-content container">
      <router-view/>
      <iframe id="background-login-iframe" style="display: none;" src="/background-login.html"></iframe>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/shared/Navbar.vue";
import {registerBackgroundLoginCallback} from "@/service/background-login-service";
import { OnlineOfflineService } from './service/online-offline-service';

export default {
  name: "home",
  components: {
    Navbar
  },
  created() {
    registerBackgroundLoginCallback();
    const onlineChecker = new OnlineOfflineService();
    onlineChecker.checkIfOnline();
    onlineChecker.startOnlineOfflineChecker();
  }
};
</script>

<style>
.main-content {
  padding-top: 15px;
}

</style>
