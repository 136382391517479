<template>
  <div>
    <span v-if="!isNew">Edit 1</span>
    <span v-if="isNew">New 1</span>
    <div>
      <input type="text" v-model="todoList.name">
    </div>

    <button type="button" @click="save">Save</button>
  </div>
</template>

<script>
import { A_SAVE_TODO_LIST, A_LOAD_TODO_LISTS } from "@/store/storeConstants";
import { mapActions } from "vuex";
export default {
  name: "TodoListEdit",
  props: ["id", "isNew"],
  data() {
    return {
      todoList: {}
    };
  },
  async created() {
    if (!this.isNew && this.id) {
        let todoLists = await this.A_LOAD_TODO_LISTS();
        todoLists = todoLists.filter(list => list.todoList.id === this.id);
        this.todoList = todoLists[0].todoList;
    }
  },
  methods: {
    ...mapActions([A_SAVE_TODO_LIST, A_LOAD_TODO_LISTS]),
    async save() {
      try {
        await this.A_SAVE_TODO_LIST({
          todoList: this.todoList,
          isNew: this.isNew
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
      
    }
  }
};
</script>
