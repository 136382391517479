import {BACKEND_URL} from "../constants";
import {onlineStatus} from "./app-status-service";

export class OnlineOfflineService {

    constructor() {
    }

    startOnlineOfflineChecker() {
        // TODO: change Timeout
        this.timeoutId = setTimeout(() => {
            this.checkIfOnline();
            this.startOnlineOfflineChecker();
        }, 50000);
    }

    async checkIfOnline() {
        let result = null;
        try {
            const response = await fetch(`${BACKEND_URL}api/version`);
            if(!response.status.toString(10).startsWith("2")){
                throw new Error("Not successful");
            }
            result = true;
        } catch(err) {
            result = false;
        }
        if(result !== onlineStatus.getValue()) {
            onlineStatus.next(result);
        }
    }
}