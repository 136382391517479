<template>
  <div>
    <h1>Login</h1>
    <a :href="googleApiAuthUrl" class="button">Google Login now</a>
    <a :href="gitlabApiAuthUrl" class="button">Gitlab Login now</a>

    <div class="modal">
    <input id="modal_1" type="checkbox" ref="showModalWindow"/>
    <label for="modal_1" class="overlay"></label>
      <article>
        <header>
          <div style="height: 1em;"><label for="modal_1" class="close">&times;</label></div>
        </header>
        <section class="content">
          It seems like this is your first login, please give yourself a username:
          <input type="text" v-model="userName"/>
        </section>
        <footer>
          <a class="button" @click="updateUserAndContinue">Continue</a>
          <label for="modal_1" class="button dangerous">
            Cancel
          </label>
        </footer>
      </article>
    </div>

  </div>
</template>

<script>
import { BACKEND_URL } from '../constants';
import { mapActions } from 'vuex';
import { A_LOGIN_USER, A_UPDATE_USER } from '@/store/storeConstants';
import { setToken } from '../apollo-client';

// TODO add previous logins here for quick login - only add login-hint to params
// export previous logins component - v-on:userSelected=callback for click on user
export default {
  name: 'login',
  created(){
    const urlSearchParams = new URLSearchParams(window.location.search);
    if(urlSearchParams.has("error")){
      // eslint-disable-next-line
      console.log("ERROR", urlSearchParams.get("error"));
    }
    if(urlSearchParams.has("token")){
      this.apiToken = urlSearchParams.get("token");
      this[A_LOGIN_USER](this.apiToken).then(userInfo => {
        
        if(userInfo.firstLogin){
          this.user = userInfo.user;
          this.$refs.showModalWindow.checked = true;
        }else{
          this.returnToReturnTarget();
        }
      });
    
    urlSearchParams.delete("token");
    const newUrl = new URL(window.location.href);
    newUrl.search = urlSearchParams.toString();
    window.history.replaceState({}, document.title, newUrl.toString());
    }
  },
  data(){
    return {
      userName: "",
      user: {},
      apiToken: ""
    };
  },
  computed: {

    googleApiAuthUrl(){
      return BACKEND_URL+"/api/auth?source=google&return=true&return-target="+this.currentUrl;
    },
    gitlabApiAuthUrl(){
      return BACKEND_URL+"/api/auth?source=gitlab&return=true&return-target="+this.currentUrl;
    },

    currentUrl(){
      return encodeURIComponent(window.location.href);
    }
  },
  methods: {
    async updateUserAndContinue(){
      this.user.displayName = this.userName;
      setToken(this.apiToken);
      await this.A_UPDATE_USER(this.user);
      this.returnToReturnTarget();
    },
    ...mapActions([
      A_LOGIN_USER, A_UPDATE_USER
    ]),

    returnToReturnTarget(){
      if(this.$route.query.returnTarget){
        this.$router.push({path: this.$route.query.returnTarget});
      }else{
        this.$router.push({name: "home"});
      }
    }
  }
};
</script>
