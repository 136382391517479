/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { updateAvailable } from "@/service/app-status-service";

export let registration = null;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (reg) {
      registration = reg;
      console.log('App is being served from cache by a service worker.');
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    updated () {
      console.log('New content is available; please refresh.');
      updateAvailable.next(true); 
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
}

if(navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('controllerchange', () => window.location.reload());
}
