export const FRAGMENT_USER = `
    fragment UserFields on UserInfo {
        user {
            displayName,
            email,
            id,
            active,
            lastActive,
            authSource
        }
        firstLogin,
        token
    }
`;

export const QUERY_GET_USER_INFO = 
`
query {
    userInfo{
        ...UserFields
    }
}
${FRAGMENT_USER}`;

export const FRAGMENT_TODO_LIST_ITEM = `
    fragment TodoListItem on TodoItem {
        id,
        done,
        weight,
        name,
        data,
        description
    }
`;

export const FRAGMENT_USER_TODO_LIST = `
    fragment UserTodoListFields on UserTodoList {
            todoList {
                id,
                name
            }
            permission
    }
`;

export const QUERY_GET_USER_TODO_LISTS = `
    query ($todoListIds: [ID!]!){
	    userTodoLists(todoListIds: $todoListIds){
            ...UserTodoListFields
        }
    }
    ${FRAGMENT_USER_TODO_LIST}`;

export const QUERY_GET_TODO_LIST_ITEMS_FOR_LIST_ID = `
query ($todoListIds: [ID!]!){ 
    userTodoLists(todoListIds: $todoListIds){
    todoList{
      id,
      name,
      items{
        ...TodoListItem
      }
    }
  }
  }
  ${FRAGMENT_TODO_LIST_ITEM}`;

export const UPDATE_USER_MUTATION = `
mutation ($id: ID!, $email: String, $displayName: String, $active: Boolean) {
    updateUser(user: {id: $id, email: $email, displayName: $displayName, active: $active})
    {
      ...UserFields
    }
  }
  ${FRAGMENT_USER}`;

export const MUTATION_UPDATE_TODO_LIST = `
mutation ($todoList: UpdateTodoListInput!) {
      updateTodoList(todoList: $todoList){
        ...UserTodoListFields
        }
    }
    ${FRAGMENT_USER_TODO_LIST}`;

export const MUTATION_ADD_TODO_LIST = `
    mutation ($todoList: AddTodoListInput!) {
          addTodoList(todoList: $todoList){
            ...UserTodoListFields
            }
        }
        ${FRAGMENT_USER_TODO_LIST}`;

export const MUTATION_DELETE_TODO_LIST = `
    mutation ($todoList: DeleteTodoListInput!) {
        deleteTodoList(todoList: $todoList){
                id, name
                }
            }`;

export const MUTATION_ADD_TODO_LIST_ITEMS = `
    mutation ($todoList: ID!, $items: [NewTodoItemInput!]!) {
        addTodoListItems(todoList: $todoList, items: $items) {
            ...TodoListItem
        }
    }
    ${FRAGMENT_TODO_LIST_ITEM}
`;

export const MUTATION_UPDATE_TODO_LIST_ITEMS = `
    mutation ($todoList: ID!, $items: [UpdateTodoItemInput!]!) {
        updateTodoListItems(todoList: $todoList, items: $items) {
            ...TodoListItem
        }
    }
    ${FRAGMENT_TODO_LIST_ITEM}
`;

export const MUTATION_DELETE_TODO_LIST_ITEMS = `
    mutation ($todoList: ID!, $items: [ID!]!) {
        deleteTodoItems(todoList: $todoList, items: $items) {
            ...TodoListItem
        }
    }
    ${FRAGMENT_TODO_LIST_ITEM}
`;