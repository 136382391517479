// MUTATIONS
export const M_ADD_TODO_LIST = 'M_ADD_TODO_LIST';
export const M_SET_TODO_LISTS = 'M_SET_TODO_LISTS';
export const M_DELETE_TODO_LIST = 'M_DELETE_TODO_LIST';
export const M_UPDATE_TODO_LIST = 'M_UPDATE_TODO_LIST';

export const M_SET_TODO_LIST_ITEMS_ON_LIST = 'M_SET_TODO_LIST_ITEMS_ON_LIST';
export const M_DELETE_TODO_ITEMS = 'M_DELETE_TODO_ITEMS';
export const M_UPDATE_TODO_ITEMS = 'M_UPDATE_TODO_ITEMS';
export const M_ADD_TODO_ITEMS = 'M_ADD_TODO_ITEMS';

export const M_SET_LOGGED_IN_USER = "M_SET_LOGGED_IN_USER";
export const M_LOGOUT_USER = "M_LOGOUT_USER";

export const M_INC_LOADING = "M_INC_LOADING";
export const M_DEC_LOADING = "M_DEC_LOADING";
export const M_SET_LOADING_STATUS = "M_SET_LOADING_STATUS";

// ACTIONS
export const A_LOGIN_USER = "A_LOGIN_USER";
export const A_UPDATE_USER = "A_UPDATE_USER";
export const A_LOGOUT_USER = "A_LOGOUT_USER";

// LOADING INDICATORS
export const A_INC_LOADING = "A_INC_LOADING";
export const A_DEC_LOADING = "A_DEC_LOADING";
export const A_LOADING_ERROR = "A_LOADING_ERROR";
export const A_LOADING_SUCCESS = "A_LOADING_SUCCESS";

export const A_SAVE_TODO_LIST = "A_SAVE_TODO_LIST";
export const A_DELETE_TODO_LIST = "A_DELETE_TODO_LIST";
export const A_LOAD_TODO_LISTS = "A_LOAD_TODO_LISTS";

export const A_LOAD_TODO_LIST_ITEMS = "A_LOAD_TODO_LIST_ITEMS";
export const A_SAVE_TODO_ITEMS = "A_SAVE_TODO_ITEMS";
export const A_UPDATE_TODO_ITEMS = "A_UPDATE_TODO_ITEMS";
export const A_DELETE_TODO_ITEMS = "A_DELETE_TODO_ITEMS";