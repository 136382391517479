<template>
  <div>
    <h1>Todo List Overview</h1>
    <div class="flex">
      <div v-for="(item, index) in todoLists" :key="index">
        <div class="card clickable" @click.self="redirectToItems(item.todoList.id)">{{item.todoList.name}}<br />
          <button @click="editTodoList(item.todoList.id)">Edit</button>
          <button @click="deleteList(item.todoList.id)">Delete</button>
        </div>
      </div>
    </div>
    <div class="flex three center">
      <div><button @click="addNewTodoList">Add new</button></div>
    </div>
  </div>
</template>

<script>
import { A_LOAD_TODO_LISTS, A_DELETE_TODO_LIST } from "@/store/storeConstants";
import { mapActions, mapState } from "vuex";
export default {
  name: "TodoListOverview",
  created() {
      this.loadTodoLists();
  },
  methods: {
    editTodoList(id){
      this.$router.push({ name: "todo-list-edit-with-id", params: {id} });
    },
    addNewTodoList() {
      this.$router.push({ name: "todo-list-edit", query: { new: true } });
    },
    redirectToItems(todoListId){
      this.$router.push({ name: "todo-list", params: { id: todoListId } });
    },
    async deleteList(id){
      // show popup - really want to delete?
      await this.deleteTodoList({ id });
      // show toast ?
    },
    ...mapActions({
      loadTodoLists: A_LOAD_TODO_LISTS,
      deleteTodoList: A_DELETE_TODO_LIST
    })
  },
  computed: mapState({
    todoLists: state => state.todoLists
  })
};
</script>

<style scoped>
</style>
