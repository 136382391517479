<template>
  <div>
    <span v-if="!isNew">Edit 1</span>
    <span v-if="isNew">New 1</span>
    <div class="flex two">
      <div>
        Name
        <input type="text" v-model="item.name" />
      </div>
      <div>
        Description
        <textarea v-model="item.description" />
      </div>
    </div>
      <div>
        Data
        <div class="flex ten grow" v-for="(val, key) in item.data || {}" :key="key">
          <div class="two-fifth"><input type="text" :value="key" @change="updateValueOfkeyInData(key,$event.target.value, val)" /></div>
          <div class="two-fifth"><input type="text" :value="val" @change="updateValueOfkeyInData(key, key, $event.target.value)" /></div>
          <div>Delete</div>
        </div>
        <div class="flex two" v-if="addCharacteristic">
         <div><input type="text" :v-model="newChraracteristic.key" @change="changeOfNewCharacteristic($event.target.value, newChraracteristic.value )" /></div>
         <div><input type="text" :v-model="newChraracteristic.value" @change="changeOfNewCharacteristic(newChraracteristic.key, $event.target.value)" /></div> 
        </div>
        <div class="flex two center">
          <input type="button" value="add characteristic" @click="addNewCharacteristic()" />
        </div>
      </div>

    <button type="button" @click="save">Save</button>
  </div>
</template>

<script>
import { A_SAVE_TODO_ITEMS, A_UPDATE_TODO_ITEMS } from "@/store/storeConstants";
import { mapActions, mapState } from "vuex";
import Vue from 'vue';
export default {
  name: "TodoListItemEdit",
  props: ["todoListId", "isNew", "itemId"],
  data() {
    return {
      addCharacteristic: false,
      newChraracteristic: {},
      item: {}
    };
  },
  async created() {
    if (!this.isNew && this.itemId) {
      this.item = Array.prototype.find.call(
        this.todoListItems[this.todoListId],
        item => item.id === this.itemId
      );
    }
  },
  methods: {
    addNewCharacteristic(){
      if(!this.addCharacteristic){
        this.addCharacteristic = true;
        this.newChraracteristic = {key: "", value: ""};
      }
    },
    changeOfNewCharacteristic(key, val){
      if(key){
        this.addCharacteristic = false;
        this.item.data = this.item.data || {};
        Vue.set(this.item.data, key, val || "");
      }
    },
    updateValueOfkeyInData(oldKey, newKey, value){
      this.addCharacteristic = false;
      Vue.delete(this.item.data, oldKey);
      Vue.set(this.item.data, newKey, value);
    },
    ...mapActions([A_SAVE_TODO_ITEMS, A_UPDATE_TODO_ITEMS]),
    async save() {
      try {
        if(this.isNew){
          this.item.done = false;
          await this.A_SAVE_TODO_ITEMS({
            todoListId: this.todoListId,
            items: [this.item]
          });
        }else{
          await this.A_UPDATE_TODO_ITEMS({
            todoListId: this.todoListId,
            items: [this.item]
          });
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }
  },
  computed: {
    ...mapState(["todoListItems"])
  }
};
</script>
