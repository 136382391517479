<template>
  <div>
    <h1>This is you {{ loggedInUser && loggedInUser.displayName }}</h1>
    <div class="flex three grow">
      <div>Username<input v-model="loggedInUser.displayName" /></div>
    </div>
    <div class="flex three grow">
      <div>
      <label class="checkable">Remember me?</label>
      </div>
      <div>
      <span>
      <label>
        <input
          type="checkbox"
          v-model="rememberMeVal"
          v-on:input="rememberMeChange($event.target.checked)"
        />
        <span class="checkable"></span>
      </label></span>
      </div>
    </div>

    <div>
      <button type="button" @click="updateUser()">Save</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { A_UPDATE_USER } from "@/store/storeConstants";
import { UserDbService } from "@/service/user-db-service";

export default {
  async created(){
    this.userDBService = new UserDbService();
    this.rememberMeVal = await this.userDBService.isUserRememberMeActivated(this.loggedInUser);
  },
  data() {
    return {
      rememberMeVal: false,
    };
  },
  methods: {
    async rememberMeChange(value) {
      if (value) {
        await this.userDBService.saveRememberMeUser(this.loggedInUser);
      } else {
        await this.userDBService.deleteRememberMeUser(this.loggedInUser);
      }
    },
    updateUser() {
      this[A_UPDATE_USER](this.loggedInUser)
        .then((data) => {
          // eslint-disable-next-line
          console.log(data);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log("CATCH:", error);
        });
    },
    ...mapActions([A_UPDATE_USER]),
  },
  computed: {
    ...mapState(["loggedInUser"]),
  },
};
</script>
