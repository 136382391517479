import FetchQL from 'fetchql';
import {BACKEND_URL} from "./constants";

const uri = BACKEND_URL + "api/graphql";
const tokenKey = "rworld-entry-token";

const interceptors = [
    {
        request: function (url, config) {
            // Modify the url or config here
            config.headers["authorization"] = localStorage.getItem(tokenKey) || null;
            return [url, config];
        },
    }
];

export const graphqlClient2 = new FetchQL({ url: uri, interceptors });

export function setToken(token) {
    localStorage.setItem(tokenKey, token);
}

export function onLogout() {
    localStorage.removeItem(tokenKey);
}