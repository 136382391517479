import Vue from 'vue';
import Vuex from 'vuex';
import {A_LOAD_TODO_LISTS, A_LOGIN_USER, A_LOGOUT_USER, M_LOGOUT_USER, A_SAVE_TODO_LIST, A_DELETE_TODO_LIST, A_UPDATE_USER, A_LOAD_TODO_LIST_ITEMS, A_SAVE_TODO_ITEMS, A_UPDATE_TODO_ITEMS, A_DELETE_TODO_ITEMS} from './storeConstants';
import {Actions} from './actions';
import {mutations} from './mutations';
import {increaseLoadingCount, loadingError, loadingSuccess} from '../service/app-status-service';

Vue.use(Vuex);

const actions = new Actions();

export default new Vuex.Store({
  state: {
    todoLists: [],
    todoListItems: {}, // id of todoList is key: value = items[]
    loggedInUser: null,
    apiToken: null,
  },
  mutations: mutations,
  actions: {

    [A_LOGOUT_USER]({commit}) {
      commit(M_LOGOUT_USER);
    },

    async [A_LOGIN_USER]({commit}, apiToken) {
      return actions[A_LOGIN_USER]({commit}, apiToken);
    },

    async [A_UPDATE_USER]({commit, state, dispatch}, user) {
      return await wrapStoreActionWithStatus(async () => actions[A_UPDATE_USER]({commit, state}, user), dispatch);
    },

    async [A_DELETE_TODO_LIST]({commit, dispatch}, {id}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_DELETE_TODO_LIST]({commit}, {id}), dispatch);
    },

    async [A_SAVE_TODO_LIST]({commit, dispatch}, {todoList, isNew}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_SAVE_TODO_LIST]({commit}, {todoList, isNew}), dispatch);
    },

    async [A_LOAD_TODO_LISTS]({commit, dispatch}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_LOAD_TODO_LISTS]({commit}), dispatch);
    },

    async [A_LOAD_TODO_LIST_ITEMS]({commit, dispatch}, todoListIds) {
      return await wrapStoreActionWithStatus(async () => await actions[A_LOAD_TODO_LIST_ITEMS]({commit}, todoListIds), dispatch);
    },

    async [A_SAVE_TODO_ITEMS]({commit, dispatch}, {todoListId, items}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_SAVE_TODO_ITEMS]({commit}, {todoListId, items}), dispatch);
    },

    async [A_UPDATE_TODO_ITEMS]({commit, dispatch}, {todoListId, items}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_UPDATE_TODO_ITEMS]({commit}, {todoListId, items}), dispatch);
    },

    async [A_DELETE_TODO_ITEMS]({commit, dispatch}, {todoListId, items}) {
      return await wrapStoreActionWithStatus(async () => await actions[A_DELETE_TODO_ITEMS]({commit}, {todoListId, items}), dispatch);
    }
  },
  getters: {
    isLoggedIn: state => {
      return new Boolean(state.loggedInUser).valueOf();
    }
  }
});

async function wrapStoreActionWithStatus(func) {
  increaseLoadingCount();
  try {
    const res = await func();
    loadingSuccess();
    return res;
  } catch(e) {
    loadingError(e);
    throw e;
  }
}